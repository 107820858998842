import React, { Component } from "react";
// import parse from 'html-react-parser';

class ServiceV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <section className="service-three  go-top">
        <div className="container">
          <div className="block-title-two text-center">
            <h3>
              Elevating Your Business with : A Commitment to Client-Centric
              Innovation &amp; Quality Assurance
            </h3>
          </div>
          {/* /.block-title-two text-center */}
          <div className="row">
            <div className="col-xl-3 col-lg-6">
              <div className="service-three__single">
                <div className="service-icon">
                  <img
                    src={publicUrl + "assets/images/icons/client-icon.png"}
                    alt="Icon"
                  />
                </div>
                <h3>Client-Centric</h3>
                <p>
                  Your success is our success. We work closely with you to
                  understand your goals and objectives.
                </p>
              </div>
              {/* /.service-three__single */}
            </div>
            {/* /.col-lg-3 col-md-6 */}
            <div className="col-xl-3 col-lg-6">
              <div className="service-three__single">
                <div className="service-icon">
                  <img
                    src={publicUrl + "assets/images/icons/innovation-icon.png"}
                    alt="Icon"
                  />
                </div>
                <h3>Innovation</h3>
                <p>
                  We stay ahead of the curve in technology, ensuring you always
                  have access to the latest solutions.
                </p>
              </div>
              {/* /.service-three__single */}
            </div>
            {/* /.col-lg-3 col-md-6 */}
            <div className="col-xl-3 col-lg-6">
              <div className="service-three__single">
                <div className="service-icon">
                  <img
                    src={publicUrl + "assets/images/icons/experience-icon.png"}
                    alt="Icon"
                  />
                </div>
                <h3>Experience</h3>
                <p>
                  With 5+ years in the industry, we have a proven track record
                  of success and satisfied clients.
                </p>
              </div>
              {/* /.service-three__single */}
            </div>
            {/* /.col-lg-3 col-md-6 */}
            <div className="col-xl-3 col-lg-6">
              <div className="service-three__single">
                <div className="service-icon">
                  <img
                    src={publicUrl + "assets/images/icons/Compliance-icon.png"}
                    alt="Icon"
                  />
                </div>
                <h3>Compliance</h3>
                <p>
                  We are committed to delivering high-quality, reliable
                  solutions that drive tangible results.
                </p>
              </div>
              {/* /.service-three__single */}
            </div>
            {/* /.col-lg-3 col-md-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default ServiceV2;
