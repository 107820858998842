import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import CoCDetails from './section-components/Coc-details';
// import RelatedProjct from './section-components/related-project';
import Footer from './global-components/footer';

class Code_of_conduct extends Component {
    render() {
  
      return (
        <div>
          <Navbar />
          <PageHeader headertitle="Code Of Conduct" />
          <CoCDetails />
          <Footer />
        </div>
      );
    }
  }
  
  export default withRouter(Code_of_conduct);