import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import CasestudyDetails from './section-components/case-study_ERP';
// import RelatedProjct from './section-components/related-project';
import Footer from './global-components/footer';

class caseStudy_ERP extends Component {
    render() {
      const { match } = this.props;
      const { caseStudy, caseStudyId } = match.params;
  
      return (
        <div>
          <Navbar />
          <PageHeader headertitle="Case Study" />
          <CasestudyDetails caseStudy={caseStudy} caseStudyId={caseStudyId}/>
          {/* <RelatedProjct /> */}
          <Footer />
        </div>
      );
    }
  }
  
  export default withRouter(caseStudy_ERP);