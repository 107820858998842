import React, { Component } from "react";
import { getTestimonials } from "../../contentfulCalls";
// import parse from 'html-react-parser';

class TestimonialOne extends Component {
  state = {
    testimonials: [], // To store fetched data from the backend
    isLoading: true,
  };

  componentDidMount() {
    getTestimonials()
      .then((result) => {
        const { testimonials } = result;
        this.setState({ testimonials: testimonials, isLoading: false });

        const $ = window.$;
        if ($(".js-tilt").length) {
          $(".js-tilt").tilt({
            maxTilt: 20,
            perspective: 700,
            glare: true,
            maxGlare: 0.3,
          });
        }

        if ($(".thm__owl-carousel").length) {
          $(".thm__owl-carousel").each(function () {
            var Self = $(this);
            var carouselOptions = Self.data("options");
            var carouselPrevSelector = Self.data("carousel-prev-btn");
            var carouselNextSelector = Self.data("carousel-next-btn");
            var thmCarousel = Self.owlCarousel(carouselOptions);
            if (carouselPrevSelector !== undefined) {
              $(carouselPrevSelector).on("click", function () {
                thmCarousel.trigger("prev.owl.carousel");
                return false;
              });
            }
            if (carouselNextSelector !== undefined) {
              $(carouselNextSelector).on("click", function () {
                thmCarousel.trigger("next.owl.carousel");
                return false;
              });
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching testimonials", error);
      });
  }

  render() {
    const { testimonials, isLoading } = this.state;
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    if (isLoading) {
      return (
        <div className={"new_preloader"}>
          <img
            src={publicUrl + "assets/images/Preloader.gif"}
            className="preloader__image"
            alt="Preloader"
          />
        </div>
      );
    } else {
      return (
        <section className="testimonials-one">
          <div className="container">
            <div className="testimonials-one__top">
              <div className="block-title text-left">
                <p>
                  <span>Clients Say</span>
                </p>
                <h3>
                  Know Everything About <br /> Professional Skills
                </h3>
                <div className="block-title__line" />
                {/* /.block-title__line */}
              </div>
              {/* /.block-title */}
              <div className="testimonials-one__carousel-btn__wrap">
                <a
                  href="/testimonial_one"
                  className="testimonials-one__carousel-btn-left"
                >
                  <i className="fa fa-angle-double-left" />
                </a>
                <a
                  href="/testimonial_one"
                  className="testimonials-one__carousel-btn-right"
                >
                  <i className="fa fa-angle-double-right" />
                </a>
              </div>
              {/* /.testimonials-one__carousel-btn__wrap */}
            </div>
            {/* /.testimonials-one__top */}
            <div className="testimonials-one__arrow-decor-wrap">
              <i className="fa fa-angle-right" />
              <i className="fa fa-angle-right" />
              {/* <i className="fa fa-angle-right" /> */}
              <i className="fa fa-angle-right" />
            </div>
            {/* /.testimonials-one__arrow-decor-wrap */}
            <div
              className="testimonials-one__carousel owl-carousel thm__owl-carousel owl-theme"
              data-carousel-prev-btn=".testimonials-one__carousel-btn-left"
              data-carousel-next-btn=".testimonials-one__carousel-btn-right"
              data-options='{"loop": true, "margin": 65, "stagePadding": 50, "items": 2, "smartSpeed": 700, "autoplay": true, "autoplayTimeout": 7000, "nav": false, "dots": false, "responsive": { "0": { "items": 1, "stagePadding": 20 }, "1199": { "items": 2 } }}'
            >
              {testimonials.map((testimonial, index) => (
                <div key={index} className="item">
                  <div className="testimonials-one__single">
                    <a
                      href={
                        testimonial.companyProfile
                          ? testimonial.companyProfile["en-US"]
                          : "/compayProfile"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="testimonials-one__image">
                        <img
                          src={ testimonial.imageUrl ? testimonial.imageUrl:
                            publicUrl +
                            "assets/images/testimonials/testimonial-1-1.jpg"
                          }
                          alt={imagealt}
                          style={{ width: '60px', height: '60px' }}
                        />
                      </div>
                    </a>

                    <div className="testimonials-one__content">
                      <p>
                        {testimonial.description
                          ? testimonial.description["en-US"]
                          : "Client Testimonial"}
                      </p>
                      <a
                        href={
                          testimonial.userProfile
                            ? testimonial.userProfile["en-US"]
                            : "/userProfile"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h3>
                          {testimonial.name
                            ? testimonial.name["en-US"]
                            : "Client Name"}
                        </h3>
                      </a>
                      <span>
                        {testimonial.designation
                          ? testimonial.designation["en-US"]
                          : "Client Designation"}
                      </span>
                      <i className="fa fa-quote-right testimonials-one__qoute-icon" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* /.testimonials-one__carousel owl-carousel thm__owl-carousel owl-theme */}
          </div>
          {/* /.container */}
        </section>
      );
    }
  }
}

export default TestimonialOne;
