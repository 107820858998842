import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import BlogDetails from './Blog-components/Blog-details';
import Footer from './global-components/footer';

class BlogList extends Component {
    render() {
      const { match } = this.props;
      const { blog, blogId } = match.params;
  
      return <div>
        <Navbar />
        <PageHeader headertitle="Blog Details"  />
        <BlogDetails blog={blog} blogId={blogId}/>
        <Footer />
    </div>
    }
  }
  
  export default withRouter(BlogList);
