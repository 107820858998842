import React from 'react';
import Navbar from './global-components/navbar-v3';
import Banner from './section-components/banner-v3';
import Service from './section-components/service-v2';
import AboutV3 from './section-components/about-v3';
import AboutV4 from './section-components/about-v4';
import Testimonial from './section-components/testimonial-style-one';
import BlogPost from './section-components/blog-post-v2';
import Footer from './global-components/footer';

const Home = () => {
    return <div>
        <Navbar />
        <Banner />
        <AboutV3 />
        <AboutV4 />
        <Service />
        <Testimonial />
        <BlogPost />
        <Footer />
    </div>
}

export default Home

