import React, { Component } from "react";
import { getJobs } from "../../contentfulCalls";


class Jobs_List extends Component {
  state = {
    jobs: [], // To store fetched case studies from the backend
    isLoading: true,
  };
  componentDidMount() {
    getJobs()
      .then((result) => {
        const { jobs } = result;
        this.setState({ jobs: jobs, isLoading: false });
      })
      .catch((error) => {
        console.error("Error fetching case studies:", error);
      });
  }
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    // let imagealt = "image";
    const { jobs, isLoading } = this.state;

    // jobs.forEach((j, i) => {
    //   sessionStorage.setItem(
    //     j.title["en-US"].trim().replace(/\s+/g, "-").toLowerCase(),
    //     j.jobId
    //   );
    // });
    if (isLoading) {
      return (
        <div className={"new_preloader"}>
          <img
            src={publicUrl + "assets/images/Preloader.gif"}
            className="preloader__image"
            alt="Preloader"
          />
        </div>
      );
    }

    return (
      <section className="service-eight  go-top">
        <div className="container">
          {jobs.map((job, index) => (
            <div key={index} className="service-eight__single">
              <div className="service-eight__icon">
                {/* <div className="servicepage-icon">
                <img
                  src={publicUrl + "assets/images/icons/erp-icon.png"}
                  alt="Icon"
                  style={{ width: "auto", height: "auto" }}
                />
              </div> */}
              </div>
              {/* /.service-eight__icon */}

              <div className="service-eight__content">
                <h3>
                  <a
                    href={`/careers-details/${job.title["en-US"]
                      .trim()
                      .replace(/\s+/g, "-")
                      .toLowerCase()}/${job.jobId}`}
                  >
                    {job.title["en-US"]}
                  </a>
                </h3>
                <p>
                  Location: {job.location ? job.location["en-US"] : "India"}
                </p>
                <p>
                  Experience:{" "}
                  {job.experience ? job.experience["en-US"] : "5 - 6"} years
                </p>
                <p style={{lineHeight:"25px", textAlign:"justify"}}>
                  {job.shortDescription
                    ? job.shortDescription["en-US"]
                    : "Temp Description"}
                </p>
              </div>

              <div className="service-eight__btn-block">
                <a
                  href={`/careers-details/${job.title["en-US"]
                    .trim()
                    .replace(/\s+/g, "-")
                    .toLowerCase()}/${job.jobId}`}
                  className="thm-btn service-eight__btn"
                >
                  Apply <i className="fa fa-angle-double-right" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default Jobs_List;
