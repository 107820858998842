import React, { Component } from "react";
import NotFoundImage from "./errorimage-removebg-preview.png"; 

class ErrorPageContent extends Component {
  render() {

    return (
      <div>
        <img
          src={NotFoundImage}
          alt="404"
          style={{
            display: "block",
            margin: "auto",
            maxWidth: "100%",
            height: "auto",
          }}
        />
        <div style={{ textAlign: "center" }}>
          <a
            href="/"
            style={{
              display: "inline-block",
              textAlign: "center",
              padding: "10px",
              textDecoration: "none",
              width: "50%", // Adjust as needed
              maxWidth: "150px", // Set a maximum width
              marginBottom: "30px",
            }}
            className="thm-btn banner-three__btn"
          >
            Return Home
          </a>
        </div>
      </div>
    );
  }
}

export default ErrorPageContent;
