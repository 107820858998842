import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as HashRouter, Route, Switch} from "react-router-dom";
import Home from "./components/home";
import About from "./components/about";
import ServiceV2 from "./components/service-v2";
import ServiceDetails_ERP from "./components/service-details_erp";
import ServiceDetails_Dev from "./components/service-details_dev";
import ServiceDetails_Enterprise from "./components/service-details_enterprise";
import ServiceDetails_Startup from "./components/service-details_startup";
import ServiceDetails_Blockchain from "./components/service-details_blockchain";
import CaseStudy from "./components/case-study";
import CaseStudy_ERP from "./components/case-study_ERP";
import Pricing from "./components/pricing";
import Faq from "./components/faq";
import Testimonial from "./components/testimonial";
import Team from "./components/team";
import Utilities from "./components/Utility-grid";
import BlogList from "./components/Blog-list";
import BlogDetails from "./components/Blog-details";
import Contact from "./components/contact";
import Hr_policy from "./components/hr_policy";
import Code_of_conduct from "./components/code_of_conduct";
import Job from "./components/job";
import JobDetails from "./components/job-details";
import ErrorPage from "./components/error";

class Root extends Component {
  render() {
    return (
      <HashRouter basename="/">
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/services" component={ServiceV2} />
            <Route path="/erp-software-development" component={ServiceDetails_ERP} />
            <Route
              path="/web-and-mobile-app-development"
              component={ServiceDetails_Dev}
            />
            <Route
              path="/enterprise-app-development"
              component={ServiceDetails_Enterprise}
            />
            <Route
              path="/startup-techno-support"
              component={ServiceDetails_Startup}
            />
            <Route
              path="/blockchain-development"
              component={ServiceDetails_Blockchain}
            />
            <Route path="/case-studies/:caseStudy/:caseStudyId" component={CaseStudy_ERP} />
            <Route path="/case-studies" component={CaseStudy} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/faq" component={Faq} />
            <Route path="/testimonial" component={Testimonial} />
            <Route path="/team" component={Team} />
            <Route path="/utilities" component={Utilities} />
            <Route path="/blogs/:blog/:blogId" component={BlogDetails} />
            <Route path="/blogs" component={BlogList} />
            <Route path="/careers-details/:jobTitle/:jobId" component={JobDetails} />
            <Route path="/careers" component={Job} />
            <Route path="/contact" component={Contact} />
            <Route path="/hr_policy" component={Hr_policy} />
            <Route path="/code_of_conduct" component={Code_of_conduct} />
            <Route component={ErrorPage} />
          </Switch>
        </div>
      </HashRouter>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("bizkar"));
