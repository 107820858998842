import React, { Component } from "react";
import { Link } from "react-router-dom";
// import parse from 'html-react-parser';

class ServiceV7 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    // let imagealt = 'image'

    return (
      <section className="service-eight  go-top">
        <div className="container">
          <div className="block-title-two text-center">
            {/* <p>our services</p> */}
            <h3>
              We Provide Creative <br /> Solutions For You
            </h3>
          </div>
          {/* /.block-title-two */}
          <div className="service-eight__single">
            <div className="service-eight__icon">
              <div className="servicepage-icon">
                <img
                  src={publicUrl + "assets/images/icons/erp-icon.png"}
                  alt="Icon"
                  style={{ width: "auto", height: "auto" }}
                />
              </div>
            </div>
            {/* /.service-eight__icon */}
            <div className="service-eight__content">
              <h3>
                <Link to="/erp-software-development">ERP - Consulting</Link>
              </h3>
              <p>
                Streamline your operations with our expert ERP consulting,
                optimizing your business processes for efficiency and
                productivity.
              </p>
            </div>
            {/* /.service-eight__content */}
            <div className="service-eight__btn-block">
              <Link
                to="/erp-software-development"
                className="thm-btn service-eight__btn"
              >
                Read More <i className="fa fa-angle-double-right" />
              </Link>
            </div>
            {/* /.service-eight__btn-block */}
          </div>
          {/* /.service-eight__single */}
          <div className="service-eight__single">
            <div className="service-eight__icon">
              <div className="servicepage-icon">
                <img
                  src={publicUrl + "assets/images/icons/startup-icon.png"}
                  alt="Icon"
                  style={{ width: "auto", height: "auto" }}
                />
              </div>
            </div>
            {/* /.service-eight__icon */}
            <div className="service-eight__content">
              <h3>
                <Link to="/startup-techno-support">Startup Techno Support</Link>
              </h3>
              <p>
                Propel your startup journey with our tailored technological
                support, providing the digital backbone necessary for rapid
                growth and success.
              </p>
            </div>
            {/* /.service-eight__content */}
            <div className="service-eight__btn-block">
              <Link
                to="/startup-techno-support"
                className="thm-btn service-eight__btn"
              >
                Read More <i className="fa fa-angle-double-right" />
              </Link>
            </div>
            {/* /.service-eight__btn-block */}
          </div>
          {/* /.service-eight__single */}
          <div className="service-eight__single">
            <div className="service-eight__icon">
              <div className="servicepage-icon">
                <img
                  src={publicUrl + "assets/images/icons/experience-icon.png"}
                  alt="Icon"
                  style={{ width: "auto", height: "auto" }}
                />
              </div>
            </div>
            {/* /.service-eight__icon */}
            <div className="service-eight__content">
              <h3>
                <a href="/enterprise-app-development">
                  Enterprise Application Development
                </a>
              </h3>
              <p>
                From enhancing operational efficiency to fostering innovation,
                elevate your business capabilities with our comprehensive and
                strategic approach to meet your organization's unique needs
              </p>
            </div>
            {/* /.service-eight__content */}
            <div className="service-eight__btn-block">
              <a
                href="/enterprise-app-development"
                className="thm-btn service-eight__btn"
              >
                Read More <i className="fa fa-angle-double-right" />
              </a>
            </div>
            {/* /.service-eight__btn-block */}
          </div>
          {/* /.service-eight__single */}
          <div className="service-eight__single">
            <div className="service-eight__icon">
              <div className="servicepage-icon">
                <img
                  src={publicUrl + "assets/images/icons/program-icon.png"}
                  alt="Icon"
                  style={{ width: "auto", height: "auto" }}
                />
              </div>
            </div>
            {/* /.service-eight__icon */}
            <div className="service-eight__content">
              <h3>
                <a href="/web-and-mobile-app-development">
                  Web & Mobile App Development
                </a>
              </h3>
              <p>
                Unlock the full potential of your online presence with our Web &
                Mobile App Development services. Our seasoned developers craft
                responsive websites and mobile applications to meet your unique
                business needs.
              </p>
            </div>
            {/* /.service-eight__content */}
            <div className="service-eight__btn-block">
              <a
                href="/web-and-mobile-app-development"
                className="thm-btn service-eight__btn"
              >
                Read More <i className="fa fa-angle-double-right" />
              </a>
            </div>
            {/* /.service-eight__btn-block */}
          </div>
          <div className="service-eight__single">
            <div className="service-eight__icon">
              <div className="servicepage-icon">
                <img
                  src={publicUrl + "assets/images/icons/blockchain-icon.png"}
                  alt="Icon"
                  style={{ width: "55px", height: "55px" }}
                />
              </div>
            </div>
            {/* /.service-eight__icon */}
            <div className="service-eight__content">
              <h3>
                <a href="/blockchain-development">Blockchain Development</a>
              </h3>
              <p>
                Leverage our Blockchain Development services to unlock new
                opportunities. Our experts specialize in smart contract
                development, token creation, dApp development, and blockchain
                integration. Maximize security, scalability, and usability for
                your business. Partner with us for innovative blockchain
                solutions.
              </p>
            </div>
            {/* /.service-eight__content */}
            <div className="service-eight__btn-block">
              <a
                href="/blockchain-development"
                className="thm-btn service-eight__btn"
              >
                Read More <i className="fa fa-angle-double-right" />
              </a>
            </div>
            {/* /.service-eight__btn-block */}
          </div>
          {/* /.service-eight__single */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default ServiceV7;
