import React from "react";
import Navbar from "./global-components/navbar-v2.js";
import PageHeader from "./global-components/page-header.js";
import ServiceBlockchain from "./section-components/service-details_blockchain.js";
import Footer from "./global-components/footer.js";

const ServiceV1 = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Service" />
      <ServiceBlockchain />
      <Footer />
    </div>
  );
};

export default ServiceV1;
