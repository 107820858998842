import React from 'react';
import Navbar from './global-components/navbar-v2.js';
import PageHeader from './global-components/page-header.js';
import ServiceStartup from './section-components/service-details_startup.js';
import Footer from './global-components/footer.js';

const ServiceV1 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Service"  />
        <ServiceStartup />
        <Footer />
    </div>
}

export default ServiceV1;

