import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

class AboutV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return (
      <section className="about-three  go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                src={publicUrl + "assets/images/resources/377x524.png"}
                className="about-three__moc"
                alt={imagealt}
              />
            </div>
            {/* /.col-lg-6 */}
            <div className="col-lg-6">
              <div className="about-three__content">
                <div className="block-title-two text-left">
                  <h3>Elevate excellence with Aasma Technology Solutions</h3>
                </div>
                {/* /.block-title-two */}
                <p>
                  At Aasma Technology Solutions, we specialize in providing
                  comprehensive business solutions to help your organization
                  thrive. With a strong focus on ERP, startup technology
                  assistance, and enterprise application development, we are
                  here to transform your business into a competitive force in
                  today's fast-paced world. Elevate your business with Aasma
                  Technology Solutions, where innovation, expertise, and
                  tailored solutions converge seamlessly for your journey to
                  success.
                </p>
                <Link to="/services" className="thm-btn about-three__btn">
                  Read More <i className="fa fa-angle-double-right" />
                </Link>
                {/* /.thm-btn about-three__btn */}
              </div>
              {/* /.about-three__content */}
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );


        }
}

export default AboutV3