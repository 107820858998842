import React, { Component } from "react";
import { getUtilities } from "../../contentfulCalls";

class UtilityGrid extends Component {
  state = {
    utilities: [], // To store fetched case studies from the backend
    isLoading: true,
  };

  componentDidMount() {
    getUtilities()
      .then((result) => {
        const { utilities } = result;
        this.setState({ utilities: utilities, isLoading: false });
      })
      .catch((error) => {
        console.error("Error fetching case studies:", error);
      });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    const { utilities, isLoading } = this.state;
    utilities.forEach((u, i) => {
      sessionStorage.setItem(u.title["en-US"], u.blogId);
    });
    if (isLoading) {
      return (
        <div className={"new_preloader"}>
          <img
            src={publicUrl + "assets/images/Preloader.gif"}
            className="preloader__image"
            alt="Preloader"
          />
        </div>
      );
    }
    return (
      <section className="blog-grid  go-top">
        <div className="container">
          <div className="row">
            {utilities.map((util, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="blog-one__single">
                  <a
                    href={util.link["en-US"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="blog-one_link"
                  >
                    <h3>{util.title["en-US"]}</h3>
                  </a>
                  <div className="blog-one__image">
                    <img
                      src={util.imageUrl} // Assuming each blog object has an 'image' property
                      alt={imagealt}
                      style={{ maxHeight: "300px", maxWidth: "370px" }}
                    />
                  </div>
                  <div className="blog-one__content">
                    <p style={{textAlign:"justify", lineHeight: "20px"}}>{util.description["en-US"]}</p>
                    <a
                      href={util.link["en-US"]}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="thm-btn about-three__btn"
                    >
                      Explore <i className="fa fa-angle-double-right" />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default UtilityGrid;
