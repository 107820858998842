import React, {Component} from "react";
import { withRouter } from 'react-router-dom';
import Navbar from "./global-components/navbar-v2";
import PageHeader from "./global-components/page-header";
import JobDetails from "./section-components/job-details";
import Footer from "./global-components/footer";

class JobList extends Component {
  render() {
    const { match } = this.props;
  const { jobTitle,jobId } = match.params;
  
      return <div>
      <Navbar />
      <PageHeader headertitle="Careers Details" />
      <JobDetails jobTitle={jobTitle} jobId={jobId}/>
      <Footer />
    </div>
  }
} 
export default withRouter(JobList);
