import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCaptchaValid: false,
      fullName: "",
      emailAddress: "",
      subject: "",
      message: "",
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  handleCaptchaChange = (value) => {
    this.setState({ isCaptchaValid: !!value });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "fullName") {
      // Allow only alphabets and space, and limit to 25 characters
      const regex = /^[A-Za-z ]{0,25}$/;
      if (regex.test(value)) {
        this.setState({ [name]: value });
      }
    } else if (name === "emailAddress") {
      this.setState({ [name]: value });
    } else if (name === "subject") {
      // Limit subject to 100 characters
      if (value.length <= 100) {
        this.setState({ [name]: value });
      }
    } else if (name === "message") {
      // Limit message to 300 characters
      if (value.length <= 300) {
        this.setState({ [name]: value });
      }
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.isCaptchaValid) {
      const { fullName, emailAddress, subject, message } = this.state;

      // Validate if all fields are filled
      if (!fullName || !emailAddress || !subject || !message) {
        alert("Please fill in all required fields.");
        return;
      }

      const mailtoLink = `mailto:recipient@example.com?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(
        `Name: ${fullName}\nEmail: ${emailAddress}\n\n${message}`
      )}`;

      window.location.href = mailtoLink;
    } else {
      alert("Please complete the reCAPTCHA before submitting.");
    }
  };


  render() {
    const { isLoading } = this.state;
    let publicUrl = process.env.PUBLIC_URL + "/";
    if (isLoading) {
      return (
        <div className={"new_preloader"}>
          <img
            src={publicUrl + "assets/images/Preloader.gif"}
            className="preloader__image"
            alt="Preloader"
          />
        </div>
      );
    } else {
      return (
        <div>
          <section className="contact-two">
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="contact-two__content">
                    <div className="contact-two__block">
                      <h3>Don't Hesitate</h3>
                      <p>
                        For in-person visits, please find us at the following
                        address
                      </p>
                    </div>
                    {/* /.contact-two__block */}
                    <ul className="list-unstyled contact-two__info-list">
                      <li>
                        <div className="contact-two__info-list-title">
                          <i className="far fa-map-marked" />
                          Address :
                        </div>
                        {/* /.contact-two__info-list-title */}
                        <p>
                          407, Palladium Business Hub, Mahavirnagar
                          <br /> Harikurpa Society, Chandkheda <br /> Ahmedabad,
                          Gujarat 382424
                        </p>
                      </li>
                      <li>
                        <div className="contact-two__info-list-title">
                          <i className="far fa-phone" />
                          Call Us :
                        </div>
                        {/* /.contact-two__info-list-title */}
                        <p>
                          <a href="tel:+91 7802813843">+91 7802813843</a>
                        </p>
                      </li>
                      <li>
                        <div className="contact-two__info-list-title">
                          <i className="far fa-envelope" />
                          Email Us :
                        </div>
                        {/* /.contact-two__info-list-title */}
                        <p>
                          <a href="mailto:contact@aasmatech.com">
                            contact@aasmatech.com
                          </a>
                        </p>
                      </li>
                    </ul>
                    {/* /.contact-two__info-list */}
                  </div>
                  {/* /.contact-two__content */}
                </div>
                {/* /.col-lg-5 */}
                <div className="col-lg-7">

                  <form
                    action="#"
                    onSubmit={this.handleSubmit}
                    className="contact-two__form"
                  >
                    <div className="contact-two__block">
                      <h3>Get In Touch</h3>
                      <p>
                        Reach out to us for your queries, and we will be happy
                        to help and assist you.
                      </p>
                    </div>
                    {/* /.contact-two__block */}
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="text"
                          placeholder="Full Name"
                          name="fullName"
                          value={this.state.fullName}
                          onChange={this.handleInputChange}
                          required
                        />
                      </div>
                      {/* /.col-md-6 */}
                      <div className="col-md-6">
                        <input
                          type="email"
                          placeholder="Email Address"
                          name="emailAddress"
                          value={this.state.emailAddress}
                          onChange={this.handleInputChange}
                          required
                        />
                      </div>
                      {/* /.col-md-6 */}
                      <div className="col-md-12">
                        <input
                          type="text"
                          placeholder="Subject"
                          name="subject"
                          value={this.state.subject}
                          onChange={this.handleInputChange}
                          required
                        />
                      </div>
                      {/* /.col-md-12 */}
                      <div className="col-md-12">
                        <textarea
                          name="message"
                          placeholder="Message"
                          value={this.state.message}
                          onChange={this.handleInputChange}
                          required
                        />
                        <ReCAPTCHA
                          sitekey="6Ld3-pYpAAAAAAtmnKwxwfZU6XRd5zWNge6KqRdh"
                          onChange={this.handleCaptchaChange}
                        />
                        {this.state.isCaptchaValid && (
                          <button
                            type="submit"
                            className="thm-btn contact-two__btn"
                          >
                            Send Message{" "}
                            <i className="fa fa-angle-double-right" />
                          </button>
                        )}
                        {/* /.thm-btn contact-two__btn */}
                      </div>
                      {/* /.col-md-12 */}
                    </div>
                    {/* /.row */}
                  </form>
                  {/* /.contact-two__form */}
                </div>
                {/* /.col-lg-7 */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
          </section>
          {/* /.contact-two */}
        </div>
      );
    }
  }
}

export default Contact;
