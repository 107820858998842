import React, { Component } from "react";
// import { Link } from "react-router-dom";

class Page_header extends Component {
  render() {
    let HeaderTitle = this.props.headertitle;
    let publicUrl = process.env.PUBLIC_URL + "/";
    // let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle;
    let Img = this.props.Img ? this.props.Img : "1920x450.png";

    return (
      <section
        className="page-header"
        style={{
          backgroundImage:
            "url(" + publicUrl + "assets/images/backgrounds/" + Img + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "150px",
          width: "100%",
          display: "flex",
          alignItems: "center", // Vertically center the content
        }}
      >
        <div className="container">
          <h2>{HeaderTitle}</h2>
          {/* <ul className="list-unstyled thm-breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span>{Subheader}</span>
            </li>
          </ul> */}
          {/* /.list-unstyled thm-breadcrumb */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default Page_header;
