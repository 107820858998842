import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { getBlogs } from "../../contentfulCalls";

class BlogList extends Component {
	state = {
		blogs: [], // To store fetched case studies from the backend
		isLoading: true,
	  };
	  componentDidMount() {
		getBlogs()
		  .then((result) => {
			const { blogs } = result;
			// console.log(blogs)
			this.setState({ blogs: blogs, isLoading: false });
		  })
		  .catch((error) => {
			console.error("Error fetching case studies:", error);
		  });
	  }
	  render() {
		let publicUrl = process.env.PUBLIC_URL + "/";
		let imagealt = "image";
		const { blogs, isLoading } = this.state;
	
		// blogs.forEach((b, i) => {
		//   sessionStorage.setItem(b.title["en-US"].trim().replace(/\s+/g, '-').toLowerCase(), b.blogId);
		// });
		if (isLoading) {
			return (
			  <div className={"new_preloader"}>
				<img
				  src={publicUrl + "assets/images/Preloader.gif"}
				  className="preloader__image"
				  alt="Preloader"
				/>
			  </div>
			);
		  }
	
		return (
		  <section className="blog-two blog-two__home-three go-top">
			<div className="container">
			  <div className="blog-two_top">
			  </div>
			  <div className="row">
				{blogs.map((blog, index) => (
				  <div key={index} className="col-lg-4">
					<div className="blog-two__single">
					  <div className="blog-two__image">
						{blog.imageUrl ? (
						  <img src={blog.imageUrl} alt={imagealt} />
						) : (
						  <img
							src={publicUrl + "assets/images/blog/blog-3-3.jpg"}
							alt={imagealt}
						  />
						)}
					  </div>
					  <div className="blog-two__content">
						<h3>
						  <Link to={`/blogs/${blog.title["en-US"].trim().replace(/\s+/g, '-').toLowerCase()}/${blog.blogId}`}>
							{blog.title["en-US"]}
						  </Link>
						</h3>
						<p>{blog.shortDescription["en-US"]}</p>
					  </div>
					</div>
				  </div>
				))}
			  </div>
			</div>
		  </section>
		);
	  }
}

export default BlogList;
