import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

class ServiceDetails_Dev extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return	<section className="service-details">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 clearfix">
			        <img src={publicUrl+"assets/images/resources/web-and-mobile-service.png"} alt={ imagealt } className="float-right wow fadeInLeft sizeClass" data-wow-duration="1500ms" />
			      </div>{/* /.col-lg-6 */}
			      <div className="col-lg-6">
			        <div className="service-details__content">
			          <h3>Web & Mobile App Development</h3>
			          <ul className="list-unstyled service-details__list">
			            <li>
                            Begin a transformative journey with our Web & Mobile app Development services, where innovation meets seamless user experiences. Our developers delve into a thorough analysis of your digital requirements.
                        </li>
			            <li>
                            For web development, we specialize in creating responsive, visually stunning websites that not only capture attention but also drive engagement. 
						</li>
			            <li>
                            On the mobile front, we design and develop cutting-edge applications that cater to the dynamic needs of today's users. Whether it's iOS or Android, our mobile solutions are crafted for optimal performance and scalability. 
						</li>
			          </ul>{/* /.list-unstyled service-details__list */}
			        </div>{/* /.service-details__content */}
			      </div>{/* /.col-lg-6 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>

        }
}

export default ServiceDetails_Dev