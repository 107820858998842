import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getSingleJob, createContentWithPDF } from "../../contentfulCalls";
import axios from "axios";
import Swal from "sweetalert2";
// import Sidebar from "../Blog-components/sidebar";

const JobDetails = ({ jobTitle, jobId }) => {
  const history = useHistory(); 
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [gender, setGender] = useState("male");
  const [resume, setResume] = useState("");
  const [url, setUrl] = useState("");
  const [cv, setCv] = useState("");

  useEffect(() => {
    getSingleJob(jobId)
      .then((result) => {
        const { job } = result;
        setJobs(job);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching case studies:", error);
      });
  },[jobId]);



  // Handle file type validation
  const validateFile = (file) => {
    const allowedExtensions = /^[^.]*\.(pdf|doc|docx)$/i;
    return allowedExtensions.test(file.name);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setResume("");
    } else if (validateFile(file)) {
      setResume(file);
    } else {
      // Handle invalid file type error
      alert("Invalid file type. Please upload only PDF, DOC, or DOCX files.");
      setResume("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate file extension
    if (!validateFile(resume)) {
      alert("Please upload only PDF, DOC, or DOCX files.");
      setResume("");
      return;
    }

    // Create FormData object to store form data
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("contact", contact);
    formData.append("gender", gender);
    formData.append("resume", resume);
    formData.append("url", url);
    formData.append("cv", cv);
    formData.append("jobTitle", jobs.title ? jobs.title["en-US"] : "Temp Role");

    try {
      history.push("/careers");
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your application has been submitted successfully!",
        showConfirmButton: false,
        timer: 2000,
      });

      await createContentWithPDF(formData, resume);
      console.log("Content created successfully with PDF file.");

      axios
        .post("/api/sendEmail", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.error("Error sending mail:", err));
    } catch (error) {
      console.error("Error creating content:", error);
    }
  };

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  if (isLoading) {
    return (
      <div className={"new_preloader"}>
        <img
          src={process.env.PUBLIC_URL + "/assets/images/Preloader.gif"}
          className="preloader__image"
          alt="Preloader"
        />
      </div>
    );
  } else {
    return (
      <section className="blog-list blog-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-one__content">
                <div className="blog-one__top">
                  <div className="blog-one__author">
                    <img
                      style={{ height: "40px", width: "40px" }}
                      src={
                        publicUrl + "assets/images/favicons/favicon-32x32.png"
                      }
                      alt={imagealt}
                    />
                    <p>Aasma Tech</p>
                  </div>
                  {/* /.blog-one__author */}
                  <div className="blog-one__social">
                    <span>
                      Location:{" "}
                      {jobs.location ? jobs.location["en-US"] : "India"}
                    </span>
                  </div>
                  {/* /.blog-one__social */}
                </div>
                {/* /.blog-one__top */}
                <h3 style={{ lineHeight: "30px" }}>
                  {jobs.title ? jobs.title["en-US"] : "Temp Role"}
                </h3>
                <p>
                  {jobs.description
                    ? jobs.description["en-US"]
                        .split("\n")
                        .map((paragraph, index) => (
                          <React.Fragment key={index}>
                            {paragraph.split(/(__.*?__)/).map((part, i) => {
                              if (
                                part.startsWith("__") &&
                                part.endsWith("__")
                              ) {
                                return (
                                  <strong key={i}>
                                    {part.substring(2, part.length - 2)}
                                  </strong>
                                );
                              } else {
                                return <span key={i}>{part}</span>;
                              }
                            })}
                            <br />
                          </React.Fragment>
                        ))
                    : "company Description"}
                </p>

                <br />

                <h4>Desired Skills and Experience:</h4>
                <p>
                  {jobs.requirements
                    ? jobs.requirements["en-US"]
                        .split("\n")
                        .map((paragraph, index) => (
                          <React.Fragment key={index}>
                            {paragraph.split(/(__.*?__)/).map((part, i) => {
                              if (
                                part.startsWith("__") &&
                                part.endsWith("__")
                              ) {
                                return (
                                  <strong key={i}>
                                    {part.substring(2, part.length - 2)}
                                  </strong>
                                );
                              } else {
                                return <span key={i}>{part}</span>;
                              }
                            })}
                            <br />
                          </React.Fragment>
                        ))
                    : "company requirements"}
                </p>
                <br />
              </div>
              <h3>Apply for this Job:</h3>
              <div className="blog-one__single">
                {/* Your form content goes here */}
                <form onSubmit={handleSubmit}>
                  <label htmlFor="firstname">First Name</label>
                  <input
                    type="text"
                    className="col-md-6"
                    name="firstname"
                    id="firstname"
                    value={firstName}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length <= 20) {
                        // Limit first name to 20 characters
                        setFirstName(value);
                      }
                    }}
                    placeholder="Enter First Name"
                    required
                  />
                  <label htmlFor="lastname">Last Name</label>
                  <input
                    type="text"
                    className="col-md-6"
                    name="lastname"
                    id="lastname"
                    value={lastName}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length <= 20) {
                        // Limit last name to 20 characters
                        setLastName(value);
                      }
                    }}
                    placeholder="Enter Last Name"
                    required
                  />
                  <label htmlFor="email">Enter Email</label>
                  <input
                    type="email"
                    name="email"
                    className="col-md-6"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter email"
                    required
                  />
                  <label htmlFor="tel">Contact</label>
                  <input
                    type="tel"
                    name="contact"
                    className="col-md-6"
                    id="contact"
                    value={contact}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[0-9+]*$/.test(value) && value.length <= 13) {
                        // Only allow numbers and '+' character
                        setContact(value);
                      }
                    }}
                    placeholder="Enter Mobile number"
                    required
                  />
                  <label htmlFor="gender">Gender</label>
                  <input
                    type="radio"
                    name="gender"
                    value="male"
                    id="male"
                    checked={gender === "male"}
                    onChange={(e) => setGender(e.target.value)}
                  />
                  Male
                  <input
                    type="radio"
                    name="gender"
                    value="female"
                    id="female"
                    checked={gender === "female"}
                    onChange={(e) => setGender(e.target.value)}
                  />
                  Female
                  <input
                    type="radio"
                    name="gender"
                    value="other"
                    id="other"
                    checked={gender === "other"}
                    onChange={(e) => setGender(e.target.value)}
                  />
                  Other
                  <label htmlFor="file">Upload Resume</label>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                    placeholder="Upload File"
                    required
                  />
                  <label htmlFor="url">URL (LinkedIn/ Portfolio)</label>
                  <input
                    type="url"
                    name="url"
                    id="url"
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="Enter url"
                    required
                  />
                  <label htmlFor="about">Brief</label>
                  <textarea
                    name="about"
                    id="about"
                    cols="30"
                    rows="10"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length <= 250) {
                        // Limit CV/brief to 250 characters
                        setCv(value);
                      }
                    }}
                    placeholder="About your self"
                    style={{ padding: "7px" }}
                    required
                  ></textarea>
                  <button type="submit" value="Submit">
                    Submit
                  </button>
                </form>
              </div>
            </div>
            {/* <div className="col-lg-4  go-top">
              <div className="sidebar">
                <div className="sidebar__single sidebar__search">
                  <form action="#" className="sidebar__search-form">
                    <input type="text" placeholder="Search Keywords" />
                    <button type="submit">
                      <i className="far fa-search" />
                    </button>
                  </form>
                </div>
                <div className="sidebar__single sidebar__category">
                  <h3 className="sidebar__title">
                    Category
                    <span />
                  </h3>
                  <ul className="sidebar__category-list list-unstyled">
                    {jobs.category
                      ? jobs.category["en-US"]
                          .split("\n")
                          .map((c, index) => (
                            <React.Fragment key={index}>
                              <li>{c}</li>
                            </React.Fragment>
                          ))
                      : "company category"}
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
};

export default JobDetails;
