import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

class AboutV4 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="about-four  go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-four__content">
                <div className="block-title-two text-left">
                  <h3>What do we offer</h3>
                </div>
                {/* /.block-title-two text-left */}
                <p>
                  Our team comprises a set of dedicated professionals providing
                  your firm the necessary guidance and innovative solutions.
                </p>
                <div className="about-four__box-wrap">
                  <div className="about-four__box">
                    <div className="about-four__box-icon">
                      {/* <i className="fal fa-atom-alt" /> */}
                      <div className="icon">
                      <img
                        src={publicUrl + "assets/images/icons/erp-icon.png"}
                        alt="Icon"
                      />
                      </div>
                    </div>
                    {/* /.about-four__box-icon */}
                    <div className="about-four__box-content">
                      <h3>ERP Solutions</h3>
                      <p>
                        Enhance your business operations and efficiency with our
                        advanced ERP systems, tailored to your specific
                        requirements.
                      </p>
                    </div>
                    {/* /.about-four__box-content */}
                  </div>
                  {/* /.about-four__box */}
                  <div className="about-four__box">
                    <div className="about-four__box-icon">
                    <div className="icon">
                      <img
                        src={publicUrl + "assets/images/icons/startup-icon.png"}
                        alt="Icon"
                      />
                      </div>
                    </div>
                    {/* /.about-four__box-icon */}
                    <div className="about-four__box-content">
                      <h3>Startup Techno Assistance</h3>
                      <p>
                        From inception to expansion, we offer startups the
                        technological assistance they need. Our expert guidance
                        will help your startup scale and succeed.
                      </p>
                    </div>
                    {/* /.about-four__box-content */}
                  </div>
                  {/* /.about-four__box */}
                  <div className="about-four__box">
                    <div className="about-four__box-icon">
                    <div className="icon">
                      <img
                        src={publicUrl + "assets/images/icons/material-icon.png"}
                        alt="Icon"
                      />
                      </div>
                    </div>
                    {/* /.about-four__box-icon */}
                    <div className="about-four__box-content">
                      <h3>Enterprise Application Development</h3>
                      <p>
                        Elevate your business with custom enterprise
                        applications developed by our skilled team, designed to
                        meet your unique business needs.
                      </p>
                    </div>
                    {/* /.about-four__box-content */}
                  </div>
                  {/* /.about-four__box */}
                  <div className="about-four__box">
                    <div className="about-four__box-icon">
                    <div className="icon">
                      <img
                        src={publicUrl + "assets/images/icons/program-icon.png"}
                        alt="Icon"
                      />
                      </div>
                    </div>
                    {/* /.about-four__box-icon */}
                    <div className="about-four__box-content">
                      <h3>Web and Mobile Development</h3>
                      <p>
                        We also excel in crafting web and mobile solutions that
                        meet the digital demands of today's businesses. Let us
                        bring your ideas to life
                      </p>
                    </div>
                    {/* /.about-four__box-content */}
                  </div>
                  {/* /.about-four__box */}
                </div>
                {/* /.about-four__box-wrap */}
              </div>
              {/* /.about-four__content */}
            </div>
            {/* /.col-lg-6 */}
            <div className="col-lg-6">
              <img
                src={publicUrl + "assets/images/resources/614x627_1.png"}
                className="about-four__moc"
                alt={imagealt}
                style={{ height: "627px", width: "614px" }}
              />
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default AboutV4;
