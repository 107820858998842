import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NavbarV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
      imageSource: "black.png", // Default image source
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const scrollPosition = window.scrollY;
    const isScrolled = scrollPosition > 100;
    this.setState({ scrolled: isScrolled });

    const newColor = this.state.scrolled ? "#8fa9d6" : "#535063";
    const hoverColor = this.state.scrolled ? "#ffffff" : newColor;

    const handleColorChange = (selector, color) => {
      const elements = document.querySelectorAll(selector);
      elements.forEach((element) => {
        element.style.color = color;
        element.style.transition = "color 0.3s"; // Add a smooth transition effect
      });
    };

    const handleHoverEffect = (selector, hoverColor) => {
      const elements = document.querySelectorAll(selector);
      elements.forEach((element) => {
        element.removeEventListener("mouseover", this.handleHoverIn);
        element.removeEventListener("mouseout", this.handleHoverOut);

        element.addEventListener("mouseover", () => {
          element.style.color = hoverColor;
          element.style.textDecoration = this.state.scrolled
            ? "none"
            : "underline";
        });

        element.addEventListener("mouseout", () => {
          element.style.color = newColor;
          element.style.textDecoration = "none";
        });
      });
    };

    // Change font color based on scroll position
    handleColorChange(".main-nav__navigation-box a", newColor);
    handleColorChange(".side-menu__toggler span", newColor);

    // Add hover effect based on scroll position
    handleHoverEffect(".main-nav__navigation-box a", hoverColor);
    handleHoverEffect(".side-menu__toggler span", hoverColor);
  };

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "logo";
    // let anchor = '#'
    return (
      <header className="site-header-two site-header-two__ go-top  go-top">
        <nav className="main-nav__two stricky">
          <div className="container">
            <style>
              {`
                  .main-nav__navigation-box a {
                    color: ${this.state.scrolled ? "#8fa9d6" : "#535063"};
                    transition: color 0.3s; /* Add a smooth transition effect */
                    text-decoration: none; /* Remove underline by default */
                  }

                  .main-nav__navigation-box a:hover {
                    color: ${this.state.scrolled ? "#ffffff" : "#535063"};
                    text-decoration: ${
                      this.state.scrolled ? "none" : "underline"
                    };
                    transition: color 0.3s, text-decoration 0.3s; /* Add smooth transitions */
                  }
                `}
            </style>
            <div className="main-nav__logo-box">
              <Link to="/">
                <img id="customNavImg"
                  className="logo"
                  src={publicUrl + "assets/images/" + this.state.imageSource}
                  alt={imgattr}
                />
              </Link>
            </div>
            {/* /.main-nav__logo-box */}
            <div className="main-nav__main-navigation">
              <ul className=" main-nav__navigation-box">
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/case-studies">Case Studies</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>

                {/* <li className="dropdown">
                        <a href="/navbar_pages">Pages</a>
                        <ul>
                          <li>
                            <Link to="/pricing">Pricing</Link>
                          </li>
                          <li>
                            <Link to="/faq">FAQ</Link>
                          </li>
                          <li>
                            <Link to="/testimonial">Testimonials</Link>
                          </li>
                          <li>
                            <Link to="/team">Team</Link>
                          </li>
                        </ul>
                      </li> */}

                <li>
                  <Link to="/utilities">Utilities</Link>
                  {/* <ul>
                    <li>
                      <Link to="/utilities">News Page</Link>
                    </li>
                    <li>
                      <Link to="/blog-list">News List</Link>
                    </li>
                    <li>
                      <Link to="/blog-details">News Details</Link>
                    </li>
                  </ul> */}
                  {/* /.sub-menu */}
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            {/* /.main-nav__main-navigation */}
            <div className="main-nav__right">
              <Link to="/navbar2" className="side-menu__toggler">
                <span />
              </Link>
            </div>
            {/* /.main-nav__right */}
          </div>
          {/* /.container */}
        </nav>
        {/* /.main-nav__one */}
      </header>
    );
  }
}

export default NavbarV2