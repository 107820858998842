import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import CaseStudy from './section-components/case-study';
import Footer from './global-components/footer';

const caseStudy = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Case Studies"  />
        <CaseStudy />
        <Footer />
    </div>
}

export default caseStudy;

