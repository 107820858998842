import React, { Component } from "react";
import {getHrPolicy} from "../../contentfulCalls"
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

class hr_policy_details extends Component {

    state = {
      backendData: {}, // To store fetched data from the backend
      isLoading: true,
    };

  componentDidMount() {

    getHrPolicy()
    .then((result) => {
      const {policy} = result
      this.setState({ backendData:policy, isLoading: false });
    })
    .catch((error) => {
      console.error('Error fetching case study:', error);
    });
    
  }
  render() {
    const { backendData,isLoading } = this.state;
    let publicUrl = process.env.PUBLIC_URL + "/";
    // let imagealt = "image";

    if (isLoading) {
      return (
        <div className={"new_preloader"}>
          <img
            src={publicUrl + "assets/images/Preloader.gif"}
            className="preloader__image"
            alt="Preloader"
          />
        </div>
      );
    }
    else{
    const splitText = (text) => text.split('\n').filter(line => line.trim() !== '');
    //   const title = backendData.title ? backendData.title['en-US']: "Dummy title"
      const attendance = backendData.attendance?backendData.attendance['en-US']:"Dummy Attendance policy"
      const leaves = backendData.leaves?backendData.leaves['en-US']:"Dummy Leaves policy"
      const leaveEntitlementsAfterConfirmation = backendData.leaveEntitlementsAfterConfirmation?backendData.leaveEntitlementsAfterConfirmation['en-US']:"Dummy Policy"
      const casualLeaveRules = backendData.casualLeaveRules?backendData.casualLeaveRules['en-US']:"Dummy casualLeaveRules"
      const beforeConfirmationLeaveRules = backendData.beforeConfirmationLeaveRules?backendData.beforeConfirmationLeaveRules['en-US']:"Dummy beforeConfirmationLeaveRules"
      const leaveEntitlementAfterResignation = backendData.leaveEntitlementAfterResignation ? backendData.leaveEntitlementAfterResignation["en-US"] : "Dummy leaveEntitlementAfterResignation policy";
      const ruleDescription = backendData.ruleDescription ? backendData.ruleDescription["en-US"] : "Dummy ruleDescription";
    return (
      <section className="portfolio-details" style={{"paddingTop":"40px"}}>
        <div className="container" >
          <h3>Attendance</h3>
          <ul className="list-unstyled service-details__list">
          {splitText(attendance).map((objective, index) => (
                <li key={index} style={{ fontSize: "18px",textAlign:"justify" }}>{objective}</li>
              ))
              }
          </ul>
          <br />
          <h3>Leaves</h3>
          <ul className="list-unstyled service-details__list">
          {splitText(leaves).map((objective, index) => (
                <li key={index} style={{ fontSize: "18px",textAlign:"justify" }}>{objective}</li>
              ))
              }
          </ul>
          <br />
          <h3>Leave Entitlements After Confirmation</h3>
          <ul className="list-unstyled service-details__list">
          {splitText(leaveEntitlementsAfterConfirmation).map((objective, index) => (
                <li key={index} style={{ fontSize: "18px",textAlign:"justify" }}>{objective}</li>
              ))
              }
          </ul>
          <br />
          <h3>Casual Leave Rules</h3>
          <ul className="list-unstyled service-details__list">
          {splitText(casualLeaveRules).map((objective, index) => (
                <li key={index} style={{ fontSize: "18px",textAlign:"justify" }}>{objective}</li>
              ))
              }
          </ul>
          <br />
          <h3>Before Confirmation Leave Rules</h3>
          <ul className="list-unstyled service-details__list">
          {splitText(beforeConfirmationLeaveRules).map((objective, index) => (
                <li key={index} style={{ fontSize: "18px",textAlign:"justify" }}>{objective}</li>
              ))
              }
          </ul>
          <br />
          <h3>Leave Entitlement After Resignation</h3>
          <ul className="list-unstyled service-details__list">
          {splitText(leaveEntitlementAfterResignation).map((objective, index) => (
                <li key={index} style={{ fontSize: "18px",textAlign:"justify" }}>{objective}</li>
              ))
              }
          </ul>
          <br />
          <ul className="list-unstyled service-details__list">
            <p style={{ fontSize: "18px", fontWeight:"bold",textAlign:"justify" }}>
              {ruleDescription}
            </p>
          </ul>
          <br/>
          <br/>
        </div>
      </section>
    );
  }}
}


export default hr_policy_details;
