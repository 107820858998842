import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getCaseStudies } from "../../contentfulCalls";

class CaseStudy_component extends Component {
  state = {
    caseStudies: [], // To store fetched case studies from the backend
    isLoading: true,
  };
  

  componentDidMount() {
    getCaseStudies()
      .then((result) => {
        const { caseStudies } = result;
        this.setState({ caseStudies: caseStudies, isLoading: false });
    
      })
      .catch((error) => {
        console.error("Error fetching case studies:", error);
      });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    const { caseStudies, isLoading } = this.state;
    // caseStudies.forEach((cs, i) => {
    //   sessionStorage.setItem(cs.appName["en-US"], cs.caseStudyId);
    // });
    if (isLoading) {
      return (
          <div className="new_preloader">
            <img
              src={publicUrl + "assets/images/Preloader.gif"}
              className="preloader__image"
              alt="Preloader"
            />
          </div>
          // <div>Preloader</div>
        );
    } else {
      return (
        <section className="portfolio-masonary  go-top">
          <div className="container">
            <div className="row masonary-layout">
              {caseStudies.map((caseStudy, index) => (
                <div key={index} className="col-md-6 masonary-item">
                  <div className="portfolio-two__single">
                    <div className="portfolio-two__image">
                      {caseStudy.imageUrl ? (
                        <img src={caseStudy.imageUrl} alt={imagealt} />
                      ) : (
                        <img
                          src={
                            publicUrl +
                            "assets/images/portfolio/portfolio-5-1.jpg"
                          }
                          alt={imagealt}
                        />
                      )}
                    </div>
                    <div className="portfolio-two__content">
                      <h3>
                        <Link
                          to={`/case-studies/${caseStudy.appName["en-US"]}/${caseStudy.caseStudyId}`}
                        >
                          {caseStudy.title["en-US"]}
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* /.row masonary-layout */}

            {/* <div className="text-center">
              <Link
                to="/portfolio-full"
                className="portfolio-masonary__more-btn thm-btn"
              >
                View More <i className="fa fa-angle-double-right" />
              </Link>
              <br />
              <br />
            </div> */}
            {/* /.text-center */}
            <br />
            <br />
          </div>
          {/* /.container */}
        </section>
      );
    }
  }
}

export default CaseStudy_component;
