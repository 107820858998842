import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import JobGridList from './section-components/job-list';
import Footer from './global-components/footer';

const JobList = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Careers"/>
        <JobGridList />
        <Footer />
    </div>
}

export default JobList;