import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ServiceV7 from './section-components/service-v7';
import Footer from './global-components/footer';

const ServiceV2 = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Our Services"  />
        <ServiceV7 />
        <Footer />
    </div>
}

export default ServiceV2

