const contentful = require("contentful-management");
const createContentfulClient = () => {
  return contentful.createClient({
    accessToken: "CFPAT-dcHS8veVlU49fYzCafWOLGPr_O4uCLrdoXppDzcGgfk",
  });
};

const client = createContentfulClient();

const getCaseStudies = async () => {
  const page = 1;
  const limit = 20;
  const skip = (page - 1) * limit;
  const searchTerm = "";

  try {
    const space = await client.getSpace("eyp95n4ksg4s");
    const environment = await space.getEnvironment("master");
    const entries = await environment.getEntries({
      content_type: "caseStudy",
      include: 2,
      "fields.title[match]": searchTerm,
      skip: skip,
      limit: limit,
    });

    // Using Promise.all to handle asynchronous operations
    const caseStudiesPromises = entries.items.map(async (entry) => {
      const caseStudyId = entry.sys.id;
      if (!entry.fields.image) {
        return { caseStudyId, ...entry.fields };
      } else {
        const ref = await entry.references({ include: 1 });
        const imageUrl = ref.includes.Asset[0].fields.file["en-US"].url;
        return { caseStudyId, imageUrl, ...entry.fields };
      }
    });

    // Wait for all promises to resolve
    const resolvedCaseStudies = await Promise.all(caseStudiesPromises);
    const sortedCaseStudies = resolvedCaseStudies.sort(
      (a, b) =>
        [
          "pulpit",
          "fln",
          "imr",
          "erp-consultation",
          "erp-solution",
          "gmers",
        ].indexOf(a.appName["en-US"]) -
        [
          "pulpit",
          "fln",
          "imr",
          "erp-consultation",
          "erp-solution",
          "gmers",
        ].indexOf(b.appName["en-US"])
    );
    // console.log(sortedCaseStudies);
    return { caseStudies: sortedCaseStudies };
  } catch (error) {
    console.error(error);
    return { caseStudies: [] };
  }
};

const getSingleCaseStudy = async (caseStudyId) => {
  return client
    .getSpace("eyp95n4ksg4s")
    .then((space) => space.getEnvironment("master"))
    .then((environment) => environment.getEntry(caseStudyId))
    .then((caseStudy) => {
      if (!caseStudy) {
        return { caseStudy: [] };
      }

      const caseStudyData = {
        id: caseStudy.sys.id,
        ...caseStudy.fields,
        // Add other fields as needed
      };
      return { caseStudy: caseStudyData };
      //   res.status(200).json({ success: true, caseStudy: caseStudyData });
    })
    .catch((error) => {
      console.error(error);
      return { caseStudy: [] };
    });
};

const getTestimonials = async () => {
  const page = 1;
  const limit = 20;
  const skip = (page - 1) * limit;
  const searchTerm = "";

  try {
    const space = await client.getSpace("eyp95n4ksg4s");
    const environment = await space.getEnvironment("master");
    const entries = await environment.getEntries({
      content_type: "testimonial",
      include: 2,
      "fields.title[match]": searchTerm,
      skip: skip,
      limit: limit,
    });

    // Using Promise.all to handle asynchronous operations
    const testimonialPromises = entries.items.map(async (entry) => {
      const testimonialId = entry.sys.id;
      if (!entry.fields.profilePic) {
        return { testimonialId, ...entry.fields };
      } else {
        const ref = await entry.references({ include: 1 });
        const imageUrl = ref.includes.Asset[0].fields.file["en-US"].url;
        return { testimonialId, imageUrl, ...entry.fields };
      }
    });

    // Wait for all promises to resolve
    const resolvedTestimonials = await Promise.all(testimonialPromises);

    // console.log(resolvedTestimonials);
    return { testimonials: resolvedTestimonials };
  } catch (error) {
    console.error(error);
    return { testimonials: [] };
  }
};

const getBlogs = async () => {
  const page = 1;
  const limit = 20;
  const skip = (page - 1) * limit;
  const searchTerm = "";

  try {
    const space = await client.getSpace("eyp95n4ksg4s");
    const environment = await space.getEnvironment("master");
    const entries = await environment.getEntries({
      content_type: "blogPost",
      include: 2,
      "fields.title[match]": searchTerm,
      skip: skip,
      limit: limit,
    });

    // Using Promise.all to handle asynchronous operations
    const blogsPromises = entries.items.map(async (entry) => {
      const blogId = entry.sys.id;
      if (!entry.fields.homeImage) {
        return { blogId, ...entry.fields };
      } else {
        const ref = await entry.references({ include: 1 });
        let imageUrl;
        ref.includes.Asset.forEach((element) => {
          if (element.fields.description["en-US"] === "home image") {
            imageUrl = element.fields.file["en-US"].url;
          }
        });
        return { blogId, imageUrl, ...entry.fields };
      }
    });

    // Wait for all promises to resolve
    const resolvedBlogs = await Promise.all(blogsPromises);

    // console.log(resolvedBlogs);
    return { blogs: resolvedBlogs };
  } catch (error) {
    console.error(error);
    return { blogs: [] };
  }
};

const getSingleBlog = async (blogId) => {
  try {
    const space = await client.getSpace("eyp95n4ksg4s");
    const environment = await space.getEnvironment("master");
    const blog = await environment.getEntry(blogId);

    if (!blog) {
      return { blog: [] };
    }
    if (!blog.fields.detailImage) {
      return { blog: { id: blog.sys.id, ...blog.fields } };
    } else {
      const ref = await blog.references({ include: 1 });
      let imageUrl;
      ref.includes.Asset.forEach((element) => {
        if (element.fields.description["en-US"] === "detail image") {
          imageUrl = element.fields.file["en-US"].url;
        }
      });
      // console.log({ id: blog.sys.id, imageUrl, ...blog.fields })
      return { blog: { id: blog.sys.id, imageUrl, ...blog.fields } };
    }
  } catch (error) {
    console.error(error);
    return { blog: [] };
  }
};

const getUtilities = async () => {
  const page = 1;
  const limit = 20;
  const skip = (page - 1) * limit;
  const searchTerm = "";

  try {
    const space = await client.getSpace("eyp95n4ksg4s");
    const environment = await space.getEnvironment("master");
    const entries = await environment.getEntries({
      content_type: "utility",
      include: 2,
      "fields.title[match]": searchTerm,
      skip: skip,
      limit: limit,
    });

    // Using Promise.all to handle asynchronous operations
    const utilityPromises = entries.items.map(async (entry) => {
      const utilityId = entry.sys.id;
      if (!entry.fields.image) {
        return { utilityId, ...entry.fields };
      } else {
        const ref = await entry.references({ include: 1 });
        const imageUrl = ref.includes.Asset[0].fields.file["en-US"].url;
        return { utilityId, imageUrl, ...entry.fields };
      }
    });

    // Wait for all promises to resolve
    const resolvedUtilities = await Promise.all(utilityPromises);

    // console.log(resolvedUtilities);
    return { utilities: resolvedUtilities };
  } catch (error) {
    console.error(error);
    return { utilities: [] };
  }
};

const getJobs = async () => {
  const page = 1;
  const limit = 20;
  const skip = (page - 1) * limit;
  const searchTerm = "";

  try {
    const space = await client.getSpace("eyp95n4ksg4s");
    const environment = await space.getEnvironment("master");
    const entries = await environment.getEntries({
      content_type: "jobPost",
      include: 2,
      "fields.title[match]": searchTerm,
      skip: skip,
      limit: limit,
    });

    // Using Promise.all to handle asynchronous operations
    const jobsPromises = entries.items.map(async (entry) => {
      const jobId = entry.sys.id;
      return { jobId, ...entry.fields };
    });

    // Wait for all promises to resolve
    const resolvedJobs = await Promise.all(jobsPromises);
    return { jobs: resolvedJobs };
  } catch (error) {
    console.error(error);
    return { jobs: [] };
  }
};

const getSingleJob = async (jobId) => {
  try {
    const space = await client.getSpace("eyp95n4ksg4s");
    const environment = await space.getEnvironment("master");
    const job = await environment.getEntry(jobId);
    if (!job) {
      return { job: [] };
    }
    if (!job.fields.image) {
      return { job: { id: job.sys.id, ...job.fields } };
    } else {
      const ref = await job.references({ include: 1 });
      let imageUrl;
      ref.includes.Asset.forEach((element) => {
        if (element.fields.description["en-US"] === "image") {
          imageUrl = element.fields.file["en-US"].url;
        }
      });
      // console.log({ id: blog.sys.id, imageUrl, ...blog.fields })
      return { job: { id: job.sys.id, imageUrl, ...job.fields } };
    }
  } catch (error) {
    console.error(error);
    return { job: [] };
  }
};

const createContentWithPDF = async (formData, resumeFile) => {
  // Read the resume file
  const resumeContent = await readFileAsync(resumeFile);

  return client
    .getSpace("eyp95n4ksg4s")
    .then((space) => space.getEnvironment("master"))
    .then((env) =>
      env.createAssetFromFiles({
        fields: {
          title: {
            "en-US": resumeFile.name,
          },
          file: {
            "en-US": {
              contentType: resumeFile.type,
              fileName: resumeFile.name,
              file: resumeContent,
            },
          },
        },
      })
    )
    .then((asset) => asset.processForAllLocales())
    .then((asset) => asset.publish())
    .then((asset) => asset.sys.id)
    .then((assetId) =>
      client
        .getSpace("eyp95n4ksg4s")
        .then((space) =>
          space
            .getEnvironment("master")
            .then((environment) => ({ assetId, environment }))
        )
    )
    .then(({ assetId, environment }) =>
      environment.createEntry("jobApplication", {
        fields: {
          firstName: { "en-US": formData.get("firstName") },
          lastName: { "en-US": formData.get("lastName") },
          jobTitle: { "en-US": formData.get("jobTitle") },
          email: { "en-US": formData.get("email") },
          contact: { "en-US": formData.get("contact") },
          gender: { "en-US": formData.get("gender") },
          subjects: { "en-US": JSON.stringify(formData.get("subjects")) },
          url: { "en-US": formData.get("url") },
          cv: { "en-US": formData.get("cv") },
          resume: {
            "en-US": {
              sys: {
                type: "Link",
                linkType: "Asset",
                id: assetId,
              },
            },
          },
        },
      })
    )
    .then((applicationEntry) => applicationEntry.publish())
    .then((applicationEntry) => { 
      return {success: true}})
    .catch((err) => console.log({ success: false, error: err }));
};


// Function to read file asynchronously
const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};


const getHrPolicy = async () => {
  return client
    .getSpace("eyp95n4ksg4s")
    .then((space) => space.getEnvironment("master"))
    .then((environment) => environment.getEntry("3AJknNkK8TvDaqTILPObsb"))
    .then((policy) => {
      if (!policy) {
        return { policy: [] };
      }

      const policyData = {
        id: policy.sys.id,
        ...policy.fields,
        // Add other fields as needed
      };
      return { policy: policyData };
      //   res.status(200).json({ success: true, caseStudy: caseStudyData });
    })
    .catch((error) => {
      console.error(error);
      return { policy: [] };
    });
};

const getCoC = async () => {
  return client
    .getSpace("eyp95n4ksg4s")
    .then((space) => space.getEnvironment("master"))
    .then((environment) => environment.getEntry("4UfaQLkYq1LieN1rFTNEJR"))
    .then((policy) => {
      if (!policy) {
        return { policy: [] };
      }

      const policyData = {
        id: policy.sys.id,
        ...policy.fields,
        // Add other fields as needed
      };
      return { policy: policyData };
      //   res.status(200).json({ success: true, caseStudy: caseStudyData });
    })
    .catch((error) => {
      console.error(error);
      return { policy: [] };
    });
};


export {
  getCaseStudies,
  getSingleCaseStudy,
  getTestimonials,
  getBlogs,
  getSingleBlog,
  getUtilities,
  getJobs,
  getSingleJob,
  createContentWithPDF,
  getHrPolicy,
  getCoC
};
