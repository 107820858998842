import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

class ServiceDetails_Blockchain extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 clearfix">
              <img
                src={publicUrl + "assets/images/resources/blockchain-service.png"}
                alt={imagealt}
                className="float-right wow fadeInLeft sizeClass"
                data-wow-duration="1500ms"
              />
            </div>
            {/* /.col-lg-6 */}
            <div className="col-lg-6">
              <div className="service-details__content">
                <h3>Blockchain Development</h3>
                <ul className="list-unstyled service-details__list">
                  <li>
                    Leverage our team's expertise in blockchain technologies to
                    deliver cutting-edge solutions tailored to your needs. From
                    smart contracts to decentralized applications, we have you
                    covered.
                  </li>
                  <li>
                    We understand that every business is unique. That's why we
                    offer customized blockchain development services to ensure
                    your project aligns perfectly with your goals and industry
                    requirements.
                  </li>
                  <li>
                    Our support doesn't end with deployment. We provide
                    end-to-end assistance, from initial consultation to ongoing
                    maintenance, ensuring your blockchain solution remains
                    secure, efficient, and future-ready.
                  </li>
                </ul>
                {/* /.list-unstyled service-details__list */}
              </div>
              {/* /.service-details__content */}
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default ServiceDetails_Blockchain;
