import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getBlogs } from "../../contentfulCalls";
// import parse from 'html-react-parser';

class BlogPostv2 extends Component {
  state = {
    blogs: [], // To store fetched case studies from the backend
    isLoading: true,
  };

  componentDidMount() {
    getBlogs()
      .then((result) => {
        const { blogs } = result;
        // console.log(blogs)
        this.setState({ blogs: blogs, isLoading: false });
      })
      .catch((error) => {
        console.error("Error fetching case studies:", error);
      });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    const { blogs, isLoading } = this.state;

    // blogs.forEach((b, i) => {
    //   sessionStorage.setItem(b.title["en-US"].trim().replace(/\s+/g, '-').toLowerCase(), b.blogId);
    // });
    if (isLoading) {
      // Render a loading state while data is being fetched
      return;
    }

    return (
      <section className="blog-two blog-two__home-three go-top">
        <div className="container">
          <div className="blog-two_top">
            <div className="block-title-two text-left">
              <h3>Let’s Read Latest Blog &amp; News</h3>
            </div>
          </div>
          <div className="row">
            {blogs.slice(0, 3).map((blog, index) => (
              <div key={index} className="col-lg-4">
                <div className="blog-two__single">
                  <div className="blog-two__image">
                    {blog.imageUrl ? (
                      <img src={blog.imageUrl} alt={imagealt} />
                    ) : (
                      <img
                        src={publicUrl + "assets/images/blog/blog-3-3.jpg"}
                        alt={imagealt}
                      />
                    )}
                  </div>
                  <div className="blog-two__content">
                    <h3>
                      <Link to={`/blogs/${blog.title["en-US"].trim().replace(/\s+/g, '-').toLowerCase()}/${blog.blogId}`}>
                        {blog.title["en-US"]}
                      </Link>
                    </h3>
                    <p>{blog.shortDescription["en-US"]}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Link to={"/blogs"} className="thm-btn about-three__btn" >
            Read More <i className="fa fa-angle-double-right" />
          </Link>
        </div>
      </section>
    );
  }
}

export default BlogPostv2;
