import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

class ServiceDetails_ERP extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return	<section className="service-details">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 clearfix">
			        <img src={publicUrl+"assets/images/resources/erp-service.png"} alt={ imagealt } className="float-right wow fadeInLeft sizeClass" data-wow-duration="1500ms" />
			      </div>{/* /.col-lg-6 */}
			      <div className="col-lg-6">
			        <div className="service-details__content">
			          <h3>ERP Software Development</h3>
			          <ul className="list-unstyled service-details__list">
			            <li>
							Start the journey of enhanced efficiency with our ERP consulting. Through a meticulous needs assessment, we collaborate closely with businesses, identifying specific requirements and challenges. 
			            </li>
			            <li>
							Our tailored guidance ensures that ERP systems are not just implemented but optimized to align seamlessly with your business goals. 
						</li>
			            <li>
							From process optimization to seamless data integration, our experts pave the way for informed decision-making, cost savings and the adaptability of ERP to scale with your evolving needs.
						</li>
			          </ul>{/* /.list-unstyled service-details__list */}
			        </div>{/* /.service-details__content */}
			      </div>{/* /.col-lg-6 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>

        }
}

export default ServiceDetails_ERP;