import React, { Component } from "react";
import Fab from "@mui/material/Fab";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MediaQuery from "react-responsive";

class Footer_v2 extends Component {
  componentDidMount() {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/theme.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(window).scrollTop(0);
      });
  }

  render() {
    return (
      <div>
        <div
          className="site-footer-two go-top"
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: "#1f3252",
            padding: "0",
            textAlign: "center",
            boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.1)",
            zIndex: 999,
          }}
        >
          <div
            className="container"
            style={{
              paddingBottom: "0", 
            }}
          >
            <p
              style={{
                textAlign: "center",
                margin: 0,
                color: "#8fa9d6",
                fontSize: "14px",
                paddingTop: "4.5px",
                paddingBottom: "6.5px",
              }}
            >
              <a
                href="/contact"
                style={{
                  color: "var(--thm-base)",
                }}
              >
                Aasma Technology Solutions{" "}
              </a>
              &copy; 2024 - All Right Reserved.
            </p>
          </div>
        </div>

        {/* WhatsApp icon - Display only on mobile screens */}
        <MediaQuery maxWidth={1223}>
          <Fab
            style={{
              position: "fixed",
              right: "30px",
              bottom: "30px",
              textAlign: "center",
            }}
            color="success"
            href="http://api.whatsapp.com/send?phone=+917802813843&text=Hi, I'm interested in connecting with you to discuss a software development opportunity. Can we chat about it?"
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon style={{ fontSize: "40px" }} />
          </Fab>
        </MediaQuery>
      </div>
    );
  }
}

export default Footer_v2;