import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

class ServiceDetails_Startup extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return	<section className="service-details">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 clearfix">
			        <img src={publicUrl+"assets/images/resources/startup-service.png"} alt={ imagealt } className="float-right wow fadeInLeft sizeClass" data-wow-duration="1500ms" />
			      </div>{/* /.col-lg-6 */}
			      <div className="col-lg-6">
			        <div className="service-details__content">
			          <h3>Startup Techno Support</h3>
			          <ul className="list-unstyled service-details__list">
			            <li>
                            Navigate the intricate technological landscape of startups with our empowering support services. We assist in crafting a technology strategy aligned with your business goals, accelerate time-to-market through rapid prototyping, and strategically plan for scalability.
                        </li>
			            <li>
                            Benefit from mentorship provided by our seasoned experts, ensuring that your startup's technology aligns seamlessly with your objectives. 
						</li>
			            <li>
                            Faster product development, scalable infrastructure, technology alignment, and experienced guidance are the hallmarks of our startup techno support services.
						</li>
			          </ul>{/* /.list-unstyled service-details__list */}
			        </div>{/* /.service-details__content */}
			      </div>{/* /.col-lg-6 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>

        }
}

export default ServiceDetails_Startup;