import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

class ServiceDetails_Enterprise extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return	<section className="service-details">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 clearfix">
			        <img src={publicUrl+"assets/images/resources/enterprise-service.png"} alt={ imagealt } className="float-right wow fadeInLeft sizeClass" data-wow-duration="1500ms" />
			      </div>{/* /.col-lg-6 */}
			      <div className="col-lg-6">
			        <div className="service-details__content">
			          <h3>Enterprise Application Development</h3>
			          <ul className="list-unstyled service-details__list">
			            <li>
                            Experience tailored solutions for enterprise success through our comprehensive Enterprise Application Development services.
                        </li>
			            <li>
                            Our approach begins with a smart requirement analysis to understand your business needs and goals. Custom development by our skilled professionals results in unique solutions, followed by rigorous quality assurance testing for reliable, bug-free applications.  
						</li>
			            <li>
                            Ongoing support and updates ensure continuous improvement, enhancing productivity by streamlining processes and workflows for sustained success.
						</li>
			          </ul>{/* /.list-unstyled service-details__list */}
			        </div>{/* /.service-details__content */}
			      </div>{/* /.col-lg-6 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>

        }
}

export default ServiceDetails_Enterprise;