import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import HrPolicyDetails from './section-components/hr_policy_details';
// import RelatedProjct from './section-components/related-project';
import Footer from './global-components/footer';

class Hr_policy extends Component {
    render() {
  
      return (
        <div>
          <Navbar />
          <PageHeader headertitle="HR Policy" />
          <HrPolicyDetails />
          {/* <RelatedProjct /> */}
          <Footer />
        </div>
      );
    }
  }
  
  export default withRouter(Hr_policy);