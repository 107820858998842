import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import { getSingleBlog } from "../../contentfulCalls";
class BlogDetails extends Component {
  state = {
    blog: {}, // To store fetched data from the backend
    isLoading: true,
  };

  componentDidMount() {
    const {  blogId } = this.props;
    // const blogId = sessionStorage.getItem(blog);

    getSingleBlog(blogId)
      .then((result) => {
        const { blog } = result;
        this.setState({ blog: blog, isLoading: false });
      })
      .catch((error) => {
        console.error("Error fetching case study:", error);
      });
  }
  render() {
    const { blog, isLoading } = this.state;
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    if (isLoading) {
		return (
			<div className={"new_preloader"}>
			  <img
				src={publicUrl + "assets/images/Preloader.gif"}
				className="preloader__image"
				alt="Preloader"
			  />
			</div>
		  );
    }
    const paragraphs = blog.longDescription["en-US"].split("\n");

    return (
      <section className="blog-list blog-details">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-8"> */}
              <div className="blog-one__single">
                <div className="blog-one__image">
                  {blog.imageUrl ? (
                        <img src={blog.imageUrl} alt={imagealt} />
                      ) : (
                        <img
                          src={
                            publicUrl +
                            "assets/images/blog/blog-s-1-1.jpg"
                          }
                          alt={imagealt}
                        />
                      )}
                </div>
                <br/>
                <div className="blog-one__content">
                  
                  <h3>{blog.title["en-US"]}</h3>

                  {paragraphs.map((paragraph, index) => (
                  <p key={index}>
                    {paragraph.split(/__(.*?)__/g).map((chunk, idx) => (
                      idx % 2 === 0 ? chunk : <strong key={idx}>{chunk}</strong>
                    ))}
                  </p>
                ))}
                  {/* <p>{blog.longDescription['en-US'].split("\n")}</p> */}
                  <br />
                  <blockquote>
                    <p>
                      {blog.quote
                        ? blog.quote["en-US"]
                        : "It's not a faith in technology. It's faith in people."}
                        
                      <i className="fal fa-quote-right" />
                    </p>
                  </blockquote>
                  <br />
                </div>
                {/* <p className="blog-details__tags">
					<i className="far fa-tags" />
					<span>Popular Tag : </span>
					<a href="/business_page">Business,</a>
		            <a href="/SEO_page">SEO,</a>
		            <a href="/Digital_marketing">Digital Marketing</a>
				  </p> */}
                <br />
              </div>
              {/* <div className="blog-post__nav">
				  <div className="blog-post__nav-left">
				  <h3><a href="/blog_details">Building Pub Sub Service House Using Node And Redis</a></h3>
				  </div>
				  <div className="blog-post__nav-right">
				  <h3><a href="/blog_details">Once Upon Time Using Story Stre For Better Engagement</a></h3>
				  </div>
				</div> */}
            </div>
          </div>
        {/* </div> */}
      </section>
    );
  }
}

export default BlogDetails;
