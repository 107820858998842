import React, { Component } from "react";
import {getSingleCaseStudy} from "../../contentfulCalls"

class caseStudy_erp extends Component {

    state = {
      backendData: {}, // To store fetched data from the backend
      isLoading: true,
    };

  componentDidMount() {
    const {caseStudyId} = this.props;
    // const caseStudyId = sessionStorage.getItem(caseStudy)

    getSingleCaseStudy(caseStudyId)
    .then((result) => {
      const {caseStudy} = result
      this.setState({ backendData:caseStudy, isLoading: false });
    })
    .catch((error) => {
      console.error('Error fetching case study:', error);
    });
    
  }
  render() {
    const { backendData,isLoading } = this.state;
    let publicUrl = process.env.PUBLIC_URL + "/";
    // let imagealt = "image";

    if (isLoading) {
      return (
        <div className={"new_preloader"}>
          <img
            src={publicUrl + "assets/images/Preloader.gif"}
            className="preloader__image"
            alt="Preloader"
          />
        </div>
      );
    }
    else{
      const title = backendData.title ? backendData.title['en-US']: "Dummy title"
      const description = backendData.description?backendData.description['en-US']:"Dummy Description"
      const splitText = (text) => text.split('\n').filter(line => line.trim() !== '');
      const keyObjectives = backendData.keyObjectives?backendData.keyObjectives['en-US']:"Dummy KeyObjectives"
      const solutions = backendData.solutions?backendData.solutions['en-US']:"Dummy Solutions"
      const keyFeatures = backendData.keyFeatures?backendData.keyFeatures['en-US']:"Dummy KeyFeatures"
      const outcome = backendData.outcome?backendData.outcome['en-US']:"Dummy Outcome"
      const videoUrl = backendData.videoUrl ? backendData.videoUrl["en-US"] : "";
    return (
      <section className="portfolio-details">
        <div className="container">
          <h3 className="portfolio-details__title">{title}</h3>
          <br />
          <p style={{ fontSize: "18px" }}>
          {description}
          </p>
          <br />
          <h3>Key Objectives</h3>
          <ul className="list-unstyled service-details__list">
          {splitText(keyObjectives).map((objective, index) => (
                <li key={index} style={{ fontSize: "18px" }}>{objective}</li>
              ))
              }
          </ul>
          <br />
          <h3>Solution</h3>
          <ul className="list-unstyled service-details__list">
          {splitText(solutions).map((objective, index) => (
                <li key={index} style={{ fontSize: "18px" }}>{objective}</li>
              ))
              }
          </ul>
          <br />
          <h3>Key Features</h3>
          <ul className="list-unstyled service-details__list">
          {splitText(keyFeatures).map((objective, index) => (
                <li key={index} style={{ fontSize: "18px" }}>{objective}</li>
              ))
              }
          </ul>
          {/* /.list-unstyled service-details__list */}
           {videoUrl && (
            <>
              <br />
              <iframe
                width="100%"
                height="400"
                src={videoUrl}
                title="Embedded Video"
                allowFullScreen
              ></iframe>
              <br />
            </>
          )}
          

          <br />
          <h3>Outcome</h3>
          <ul className="list-unstyled service-details__list">
            <p style={{ fontSize: "18px" }}>
              {outcome}
            </p>
          </ul>
          <br/>
          <br/>
        </div>
      </section>
    );
  }}
}


export default caseStudy_erp;
