import React, { Component } from "react";
import { getCoC } from "../../contentfulCalls";
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';

class CoCDetails extends Component {
  state = {
    backendData: {}, // To store fetched data from the backend
    isLoading: true,
  };

  componentDidMount() {
    getCoC()
      .then((result) => {
        const { policy } = result;
        this.setState({ backendData: policy, isLoading: false });
      })
      .catch((error) => {
        console.error("Error fetching case study:", error);
      });
  }
  render() {
    const { backendData, isLoading } = this.state;
    let publicUrl = process.env.PUBLIC_URL + "/";
    // let imagealt = "image";

    if (isLoading) {
      return (
        <div className={"new_preloader"}>
          <img
            src={publicUrl + "assets/images/Preloader.gif"}
            className="preloader__image"
            alt="Preloader"
          />
        </div>
      );
    } else {
      const splitText = (text) =>
        text.split("\n").filter((line) => line.trim() !== "");
      const title = backendData.title
        ? backendData.title["en-US"]
        : "Dummy title";
      const startDescription = backendData.startDescription
        ? backendData.startDescription["en-US"]
        : "Dummy startDescription";
      const cleanlinessAndHygiene = backendData.cleanlinessAndHygiene
        ? backendData.cleanlinessAndHygiene["en-US"]
        : "Dummy cleanlinessAndHygiene";
      const controlledSubstancesInWorkplace =
        backendData.controlledSubstancesInWorkplace
          ? backendData.controlledSubstancesInWorkplace["en-US"]
          : "Dummy controlledSubstancesInWorkplace";
      const workPerformance = backendData.workPerformance
        ? backendData.workPerformance["en-US"]
        : "Dummy workPerformance";
      const attendanceAndPunctuality = backendData.attendanceAndPunctuality
        ? backendData.attendanceAndPunctuality["en-US"]
        : "Dummy attendanceAndPunctuality";
      const useOfFacilitiesAndDigitalAssets =
        backendData.useOfFacilitiesAndDigitalAssets
          ? backendData.useOfFacilitiesAndDigitalAssets["en-US"]
          : "Dummy useOfFacilitiesAndDigitalAssets";
      const personalActionsAndAppearance =
        backendData.personalActionsAndAppearance
          ? backendData.personalActionsAndAppearance["en-US"]
          : "Dummy personalActionsAndAppearance";
      const misconduct = backendData.misconduct
        ? backendData.misconduct["en-US"]
        : "Dummy misconduct";
      const additionalMisconduct = backendData.additionalMisconduct
        ? backendData.additionalMisconduct["en-US"]
        : "Dummy additionalMisconduct";

      const misconductItems = splitText(misconduct);
      const firstItem1 = misconductItems.shift();
      const remainingItems1 = misconductItems;

      // Split additional misconduct and get the first item and remaining items
      const additionalMisconductItems = splitText(additionalMisconduct);
      const firstItem2 = additionalMisconductItems.shift();
      const remainingItems2 = additionalMisconductItems;

      const endDescription = backendData.endDescription
        ? backendData.endDescription["en-US"]
        : "Dummy endDescription";
      return (
        <section className="portfolio-details" style={{ paddingTop: "40px" }}>
          <div className="container">
            <p style={{ fontSize: "1.5rem", fontWeight: "bold",textAlign:"justify" }}>{title}</p>
            <ul className="list-unstyled service-details__list">
              <p style={{ fontSize: "1.2rem",textAlign:"justify", marginTop:"10px" }}>{startDescription}</p>
            </ul>
            <br />
            <h5>Cleanliness and Hygiene</h5>
            <ul className="list-unstyled service-details__list">
              {splitText(cleanlinessAndHygiene).map((objective, index) => (
                <li key={index} style={{ fontSize: "1.2rem",textAlign:"justify" }}>
                  {objective}
                </li>
              ))}
            </ul>
            <br />
            <h5>Controlled substances in workplace or during working hours</h5>
            <ul className="list-unstyled service-details__list">
              {splitText(controlledSubstancesInWorkplace).map(
                (objective, index) => (
                  <li key={index} style={{ fontSize: "1.2rem",textAlign:"justify" }}>
                    {objective}
                  </li>
                )
              )}
            </ul>
            <br />
            <h5 style={{ textDecoration: "underline" }}>Prohibited Conduct</h5>
            <h5>I. Work performance</h5>
            <ul className="list-unstyled service-details__list">
              {splitText(workPerformance).map((objective, index) => (
                <li key={index} style={{ fontSize: "1.2rem",textAlign:"justify" }}>
                  {objective}
                </li>
              ))}
            </ul>
            <br />
            <h5>II. Attendance and Punctuality</h5>
            <ul className="list-unstyled service-details__list">
              {splitText(attendanceAndPunctuality).map((objective, index) => (
                <li key={index} style={{ fontSize: "1.2rem",textAlign:"justify" }}>
                  {objective}
                </li>
              ))}
            </ul>
            <br />
            <h5>III. Use of Facilities and Digital Assets</h5>
            <ul className="list-unstyled service-details__list">
              {splitText(useOfFacilitiesAndDigitalAssets).map(
                (objective, index) => (
                  <li key={index} style={{ fontSize: "1.2rem",textAlign:"justify" }}>
                    {objective}
                  </li>
                )
              )}
            </ul>
            <br />
            <h5>IV. Personal Actions and Appearance</h5>
            <ul className="list-unstyled service-details__list">
              {splitText(personalActionsAndAppearance).map(
                (objective, index) => (
                  <li key={index} style={{ fontSize: "1.2rem",textAlign:"justify" }}>
                    {objective}
                  </li>
                )
              )}
            </ul>
            <br />
            <h5>V. MISCONDUCT </h5>
            <p className="service-details__list" style={{ fontSize: "1.2rem",textAlign:"justify" }}>
              {firstItem1}
            </p>
            <ul className="list-unstyled service-details__list">
              {remainingItems1.map((objective, index) => (
                <li key={index} style={{ fontSize: "1.2rem",textAlign:"justify" }}>
                  {objective}
                </li>
              ))}
            </ul>
            <br />
            {/* <h5>V. MISCONDUCT </h5> */}
            <p className="service-details__list" style={{ fontSize: "1.2rem",textAlign:"justify" }}>
              {firstItem2}
            </p>
            <ul className="list-unstyled service-details__list">
              {remainingItems2.map((objective, index) => (
                <li key={index} style={{ fontSize: "1.2rem",textAlign:"justify" }}>
                  {objective}
                </li>
              ))}
            </ul>
            <br />

            {splitText(endDescription).map((objective, index) => (
              <p key={index} style={{ fontSize: "1.2rem", fontWeight: "bold", textAlign:"justify", marginTop:"10px" }}>
                {objective}
              </p>
            ))}

            <br />
            <br />
          </div>
        </section>
      );
    }
  }
}

export default CoCDetails;
